













import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { ProjectTeamMutations } from '@store/modules/project-team/Types';
import { IJuristicEntity } from '@store/modules/about-project/Interfaces';
import { namespace } from 'vuex-class';

const NSProjectTeam = namespace('storeProjectTeam');
const NSProject = namespace('storeProject');
const NSAboutProject = namespace('storeAboutProject');

import './scss/PopupFiltersTeam.scss';


@Component({
    name: 'PopupFiltersTeam',
    components: {
        PopupFilters: () => import('@components/popups/PopupFilters.vue'),
    }
})

export default class PopupFiltersAboutProject extends Vue {
    $refs!: {
        popupFilter: HTMLFormElement,
    }

    @Prop({}) items!: any[];
    @Prop({}) filteredItemsCount!: number;

    @NSProjectTeam.Getter('filters') filters!: any[];
    @NSProjectTeam.Getter('filtersApply') filtersApply!: boolean;
    @NSProjectTeam.Mutation(ProjectTeamMutations.M_SET_FILTERS) setFilters!: (payload: any[]) => void;
    @NSProjectTeam.Mutation(ProjectTeamMutations.M_SET_FILTERS_APPLY) setFiltersApply!: (payload: boolean) => void;
    @NSAboutProject.Getter('juristicEntities') juristicEntities!: IJuristicEntity[];

    created() {
        this.checkDataForSetOptions();
        this.setCompaniesRatio();
        this.setSelectedCheckboxesFilters();
    }

    @Watch('items')
    watchJuristicMembersList() {
        this.checkDataForSetOptions();
    }

    @Watch('filteredItemsCount')
    watchCount() {
        this.$emit('set-filters-preview', this.popupData);
    }

    popupData: any[] = [
        {
            title: 'Активность',
            block: 'status',
            type: 'select',
            key: 'status',
            options: this.allStatuses(),
            selected: [],
        }, {
            title: 'Организация',
            block: 'company',
            type: 'select',
            key: 'entityId',
            options: [],
            selected: [],
            ratio: [],
        }, {
            title: 'Роль',
            block: 'role',
            type: 'select',
            key: 'role.label',
            options: [],
            selected: [],
        },
    ];

    applyFilters() {
        this.setFiltersApply(true);
        this.closePopup();
    }

    checkDataForSetOptions() {
        if (this.items && this.items.length) {
            this.setOptions();
        }
    }

    cancelFilters() {
        this.setFiltersApply(false);

        this.popupData.forEach((item) => {
            if (item.type === 'checkboxes' || item.type === 'select') {
                item.selected = [];
            }
        });

        this.setFilters([]);
        this.$refs.popupFilter.resetFilters();
    }

    setOptions() {
        this.popupData.forEach((item) => {
            if (item.block === 'status') {
                item.options = this.allStatuses();
            }
            if (item.block === 'company') {
                item.options = this.allCompanies();
            }
            if (item.block === 'role') {
                item.options = this.allIndividualPersonsRoles();
            }
        })
    }

    allStatuses(): any[] {
        return [
            { label: 'Портал, активен' },
            { label: 'Портал, не активен' },
            { label: 'Инженерная часть, активен' },
            { label: 'Инженерная часть, не активен' },
            { label: 'Не действительны' },
        ];
    }

    setSelectedCheckboxesFilters() {
        this.filters.forEach((filter) => {
            this.popupData.forEach((data) => {
                if (data.block === filter.block) {
                    data.selected = filter.selected;
                }
            });
        });
    }

    setCompaniesRatio(): void {
        this.popupData.forEach(item => {
            if (item.block === 'company') {
                const output: any[] = [];
                const companies: { label: string }[] = this.allCompanies();
                if (companies && companies.length && this.juristicEntities) {
                    companies.forEach((item: { label: string }) => {
                        const itemById: IJuristicEntity[] = this.juristicEntities.filter(entity => entity.id === item.label);
                        if (itemById.length) {
                            output.push({
                                value: item.label,
                                label: itemById[0].title,
                            });
                        }
                    });
                }
                item.ratio = output;
            }
        });
    }

    allCompanies(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.items) {
            for (const item of this.items) {
                if (item.entityId && item.entityId && !added.includes(item.entityId)) {
                    added.push(item.entityId);
                    output.push({ label: item.legalEntity });
                }
            }
        }
        return output;
    }

    allIndividualPersonsRoles(): any[] {
        const added: string[] = [];
        const output: any[] = [];
        if (this.items) {
            for (const item of this.items) {
                if (item.role && item.role.label && !added.includes(item.role.label)) {
                    added.push(item.role.label);
                    output.push({label: item.role.label});
                }
            }
        }
        return output;
    }

    changeSelected(e, byPreview = false) {
        for (const item of this.popupData) {
            if (item.title === e.section) {
                item.selected = e.selected;
            }
        }
        this.updateStoreFilters();
        if (byPreview) {
            this.$refs.popupFilter.changeSelectedData(e);
        }
    }

    updateStoreFilters() {
        const filters: any[] = [];
        this.popupData.forEach((item) => {
            if ((item.type === 'checkboxes' || item.type === 'select') && item.selected.length) {
                filters.push(item);
            }
        });
        this.setFilters(filters);
    }

    openPopup() {
        this.$refs.popupFilter.openPopup();
    }

    closePopup() {
        this.$refs.popupFilter.closePopup();
    }
}
